<template lang="pug">
  .row.align-items-center.top-bar
    div.m-l-15
      p.title {{ itemTitle }}
      p.name {{ itemName }}

</template>

<script>
  export default {
    props: {
      itemTitle: {
        type: String,
        default: ""
      },
      itemName: {
        type: String,
        default: ""
      },
      editLinkName: {
        type: String,
        default: ""
      }
    },

    components: {
      AppButton: () => import("@/components/elements/AppButton")
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  p
    margin: 0

  .top-bar
    background-color: $default-purple-light
    border-radius: 2px
    height: 73px
    justify-content: space-between

    .title
      color: $default-purple
      font-size: 0.8rem
      font-weight: 600

    .name
      font-size: 1.5rem
      color: $default-black
      font-weight: 400

  .edit
    background: $default-purple
    border-radius: 4px
    color: $default-white
    font-size: 0.8rem
    font-weight: 200
    height: 30px
    text-transform: uppercase
    width: 172px
</style>
